<!-- <app-loading [hidden]="load" ></app-loading> -->

<div class="header_top_menu pt-2 pb-2 bg_color">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-sm-8">
        <div class="header_top_menu_address">
          <div class="header_top_menu_address_inner">
            <ul>
              <li>
                <a href="mailto:{{email__}}"><i class="fa fa-envelope-o"></i>{{ email__ }}</a>
              </li>
              <li>
                <a href="tel:{{ph1}}"><i class="fa fa-phone"></i>{{ ph1 }} : {{ ph2 }}</a>
              </li>
              <li>
                <a href="#"><i class="fa fa-clock-o"></i>{{ openHour }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-4">
        <div class="header_top_menu_icon">
          <div class="header_top_menu_icon_inner">
            <ul>
              <li>
                <a *ngIf="facebook" target="_blank" href="{{ facebook }}"
                  ><i class="fa fa-facebook"></i
                ></a>
              </li>
              <li>
                <a *ngIf="tweeter" target="_blank" href="{{ tweeter }}"
                  ><i class="fa fa-twitter"></i
                ></a>
              </li>
              <li>
                <a *ngIf="instagram" target="_blank" href="{{ instagram }}"
                  ><i class="fa fa-instagram"></i
                ></a>
              </li>
              <li>
                <a *ngIf="LnkdIn" target="_blank" href="{{ LnkdIn }}"
                  ><i class="fa fa-linkedin"></i
                ></a>
              </li>
              <li>
                <a *ngIf="pinterest" target="_blank" href="{{ pinterest }}"
                  ><i class="fa fa-pinterest"></i
                ></a>
              </li>
              <li>
                <a *ngIf="telegram" target="_blank" href="{{ telegram }}"
                  ><i class="fa fa-telegram"></i
                ></a>
              </li>
              <li>
                <a *ngIf="youtube" target="_blank" href="{{ youtube }}"
                  ><i class="fa fa-youtube"></i
                ></a>
              </li>
              <li>
                <a *ngIf="map" target="_blank" href="{{ map }}"
                  ><i class="fa fa-map-marker"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  id="sticky-header"
  class="techno_nav_manu d-md-none d-lg-block d-sm-none d-none"
>
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <div class="logo mt-4">
          <a class="logo_img" routerLink="/" title="glint">
            <img
              src="{{ imageUrl }}{{ LogoHeader1 }}"
              style="max-width: 77px; margin-top: -12px"
              alt=""
            />
          </a>
          <a class="main_sticky" routerLink="/">
            <img
              src="{{ imageUrl }}{{ LogoHeader1 }}"
              style="max-width: 77px; margin-top: -12px"
              alt="astute"
            />
          </a>
        </div>
      </div>
      <div class="col-md-9">
        <nav class="techno_menu">
          <ul>
            <li>
              <a routerLink="/">{{ home }}</a>
            </li>
            <li>
              <a routerLink="about">{{ aboutUs }}</a>
            </li>
            <li>
              <a routerLink="services">{{ services }}</a>
            </li>
            <li>
              <a routerLink="ourWorks">{{ ourWorks }}</a>
            </li>
            <!-- <li>
                            <a>{{ourWorks}}</a>
                            <ul class="sub-menu">
                                <li *ngFor="let item of OurWorks"><a routerLink="../productCategories/{{item.postID}}">{{item.postTitle}}</a></li>
                            </ul>
                        </li> -->
            <li>
              <a routerLink="/contactUs">{{ contactUs }}</a>
            </li>
            <li style="margin-right: 10px">
              <a>
                <i class="fa fa-language" style="font-size: 23px"></i
                ><span></span>
              </a>

              <ul class="sub-menu">
                <li (click)="lang('Ar')">
                  <a>عربى</a>
                </li>
                <li (click)="lang('En')">
                  <a>English</a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>

<div class="pos-f-t d-sm-block d-md-block d-lg-none">
  <nav class="navbar" style="background-color: #0762f8 !important">
    <button
      class="navbar-toggler"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseBasic"
    >
      <span
        ><i class="fa fa-bars" style="font-size: 24px; color: #fff"></i
      ></span>
    </button>

    <span style="color: #fff">Glint</span>
  </nav>

  <div [collapse]="isCollapsed">
    <div class="p-4">
      <div
        class="well well-lg card card-block card-header"
        style="
          background-color: rgb(255 255 255);
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        "
      >
        <a style="color: #000000c4" routerLink="/">{{ home }}</a>
      </div>
      <div
        class="well well-lg card card-block card-header"
        style="
          background-color: rgb(255 255 255);
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        "
      >
        <a style="color: #000000c4" routerLink="about">{{ aboutUs }}</a>
      </div>
      <div
        class="well well-lg card card-block card-header"
        style="
          background-color: rgb(255 255 255);
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        "
      >
        <a style="color: #000000c4" routerLink="services">{{ services }}</a>
      </div>
      <div
        class="well well-lg card card-block card-header"
        style="
          background-color: rgb(255 255 255);
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        "
      >
        <a style="color: #000000c4" routerLink="news">{{ News }}</a>
      </div>
      <div
        class="well well-lg card card-block card-header"
        style="
          background-color: rgb(255 255 255);
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        "
      >
        <a style="color: #000000c4" routerLink="contactUs">{{ contactUs }}</a>
      </div>
      <div
        class="well well-lg card card-block card-header"
        style="cursor: pointer; background-color: rgb(255 255 255)"
      >
        <a
          style="color: #000000c4"
          (click)="isCollapsed2 = !isCollapsed2"
          [attr.aria-expanded]="!isCollapsed2"
          aria-controls="collapseBasic2"
          ><a
            class="mean-expand"
            style="font-size: 18px; float: right; cursor: pointer"
            >+</a
          >
          {{ ourWorks }}
        </a>
      </div>
      <div id="collapseBasic2" [collapse]="isCollapsed2" [isAnimated]="true">
        <div
          class="well well-lg card card-block card-header"
          style="background: #fff"
        >
          <a
            *ngFor="let item of OurWorks"
            routerLink="../productCategories/{{ item.postID }}"
            style="
              text-align: center;
              border-bottom: 1px solid;
              margin-bottom: 5px;
              color: #000000c4;
            "
          >
            {{ item.postTitle }}</a
          >
        </div>
      </div>

      <div
        class="well well-lg card card-block card-header"
        style="cursor: pointer; background-color: rgb(255 255 255)"
      >
        <a
          style="color: #000000c4"
          (click)="isCollapsed3 = !isCollapsed3"
          [attr.aria-expanded]="!isCollapsed3"
          aria-controls="collapseBasic3"
          ><a
            class="mean-expand"
            style="font-size: 18px; float: right; cursor: pointer"
            >+</a
          >
          <i class="fa fa-language" style="font-size: 23px"></i><span></span>
        </a>
      </div>
      <div id="collapseBasic3" [collapse]="isCollapsed3" [isAnimated]="true">
        <div
          class="well well-lg card card-block card-header"
          style="background: #fff"
        >
          <a
            (click)="lang('Ar')"
            style="
              text-align: center;
              border-bottom: 1px solid;
              margin-bottom: 5px;
              color: #000000c4;
              cursor: pointer;
            "
          >
            عربى
          </a>
          <a
            (click)="lang('En')"
            style="
              text-align: center;
              border-bottom: 1px solid;
              margin-bottom: 5px;
              color: #000000c4;
              cursor: pointer;
            "
          >
            English
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <router-outlet (activate)="onActivate($event)"></router-outlet> -->
<router-outlet></router-outlet>

<div
  class="footer-middle pt-95"
  style="background-image: url(assets/images/call-bg.png)"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="widget widgets-company-info">
          <div class="footer-bottom-logo pb-40">
            <img
              src="{{ LogoFooter }}"
              style="
                max-width: 237px; /* max-height: 77px; */
                margin-top: -13px;
              "
              alt=""
            />
          </div>
          <div class="company-info-desc">
            <div [innerHtml]="brnAbout" style="color: #000; size: 20px"></div>
          </div>
        </div>
        <h4 class="widget-title pb-4">{{ SocialMedia }}</h4>
        <div class="follow-company-icon">
          <a *ngIf="facebook" target="_balnk" href="{{ facebook }}"
            ><i class="fa fa-facebook"></i
          ></a>
          <a *ngIf="tweeter" target="_balnk" href="{{ tweeter }}"
            ><i class="fa fa-twitter"></i
          ></a>
          <a *ngIf="LnkdIn" target="_balnk" href="{{ LnkdIn }}"
            ><i class="fa fa-linkedin"></i
          ></a>
          <a *ngIf="pinterest" target="_balnk" href="{{ pinterest }}"
            ><i class="fa fa-pinterest"></i
          ></a>
          <a *ngIf="telegram" target="_balnk" href="{{ telegram }}"
            ><i class="fa fa-telegram"></i
          ></a>
          <a *ngIf="instagram" target="_balnk" href="{{ instagram }}"
            ><i class="fa fa-instagram"></i
          ></a>
          <a *ngIf="youtube" target="_balnk" href="{{ youtube }}"
            ><i class="fa fa-youtube"></i
          ></a>
          <a *ngIf="map" target="_balnk" href="{{ map }}"
            ><i class="fa fa-map-marker"></i
          ></a>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">
        <div class="widget widget-nav-menu">
          <h4 class="widget-title pb-4">{{ SiteMap }}</h4>
          <div class="menu-quick-link-container ml-4">
            <ul id="menu-quick-link" class="menu">
              <li>
                <a routerLink="/">{{ home }}</a>
              </li>
              <li [hidden]="true">
                <a routerLink="about">{{ aboutCampay }}</a>
              </li>
              <li>
                <a routerLink="productCategories">{{ products }}</a>
              </li>
              <li>
                <a routerLink="advertisement">{{ advertisement }}</a>
              </li>
              <li>
                <a routerLink="news">{{ News }}</a>
              </li>
              <li><a (click)="lang('Ar')">عربي</a></li>
              <li><a (click)="lang('En')">English</a></li>
              <li>
                <a routerLink="contactUs">{{ contactUs }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-6 col-sm-12">
        <div class="widget widgets-company-info">
          <h3 class="widget-title">
            {{ location }}
          </h3>
          <div class="company-info-desc">
            <p>
              {{ address_ }}
            </p>
          </div>
          <div class="footer-social-info">
            <p>
              <span>{{ Phone }} : </span>{{ ph1 }}
            </p>
          </div>
          <div class="footer-social-info">
            <p>
              <span>{{ Mobile }} : </span>{{ ph2 }}
            </p>
          </div>
          <div class="footer-social-info">
            <p>
              <span>{{ Email }} : </span>{{ email__ }}
            </p>
          </div>
          <div class="footer-social-info">
            <p>
              <span>{{ openHour_ }} : </span>{{ openHour }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4">
        <div class="row flex_direction">
          <div class="col-lg-6 col-md-12 col-sm-12 pt-4">
            <p class="copyright_en" style="color: #032c55">
              © {{year}} All rights reserved  <a href="https://newtouch.dev/" target="_blank"
              >NewTouch Smart Technology Solutions</a
            >
            </p>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 pt-4">
            <p class="copyright_ar" style="color: #032c55">
              © {{year}} كافة الحقوق محفوظة   <a href="https://newtouch.dev/" target="_blank"
              >نيوتاتش للحلول التقنية الذكية</a
            >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="fb-customerchat" page_id="6708927376315932">
</div> -->

<div class="fb-customerchat" page_id="1755810784640393"></div>
