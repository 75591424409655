import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./components/home/home.module').then((m) => m.homeModules),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./components/home/home.module').then((m) => m.homeModules),
  },
  {
    path: 'ourWorks',
    loadChildren: () =>
      import('./components/product-categories/product-categories.module').then(
        (m) => m.productCategoriesModules
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'works/:id',
    loadChildren: () =>
      import('./components/product-details/products-detials.module').then(
        (m) => m.productsDetialsModules
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'services',
    loadChildren: () =>
      import('./components/services/services.module').then(
        (m) => m.ServicesModules
      ),
  },
  {
    path: 'statistic',
    loadChildren: () =>
      import('./components/statistic/statistic.module').then(
        (m) => m.StatisticModules
      ),
  },
  {
    path: 'contactUs',
    loadChildren: () =>
      import('./components/contact-us/contact.module').then(
        (m) => m.contactModules
      ),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./components/about/about.module').then((m) => m.aboutModules),
  },
  {
    path: 'offers',
    loadChildren: () =>
      import('./components/offers/offers.module').then((m) => m.OffersModule),
  },
  {
    path: 'offers_details/:id',
    loadChildren: () =>
      import('./components/offers-details/offers-details.module').then(
        (m) => m.OffersDetailsModule
      ),
  },
  {
    path: 'clientGallery/:id',
    loadChildren: () =>
      import('./components/client-gallery/client-gallery.module').then(
        (m) => m.ClientGalleryModule
      ),
  },
  {
    path: 'WorksGallery/:id',
    loadChildren: () =>
      import('./components/works-gallery/works-gallery.module').then(
        (m) => m.WorksGalleryModule
      ),
  },
  {
    path: 'homeSrv',
    loadChildren: () =>
      import(
        './components/home/components/home-services/home-services.module'
      ).then((m) => m.HomeServicesModule),
  },
  {
    path: 'homeabo',
    loadChildren: () =>
      import('./components/home/components/home-about/home-about.module').then(
        (m) => m.HomeAboutModule
      ),
  },
  {
    path: 'homeOffer',
    loadChildren: () =>
      import(
        './components/home/components/home-offers/home-offers.module'
      ).then((m) => m.HomeOffersModule),
  },
  {
    path: 'homeWork',
    loadChildren: () =>
      import('./components/home/components/home-works/home-works.module').then(
        (m) => m.HomeWorksModule
      ),
  },
  {
    path: 'statistic',
    loadChildren: () =>
      import('./components/home/components/statistic/statistic.module').then(
        (m) => m.StatisticModule
      ),
  },
  {
    path: 'homeClient',
    loadChildren: () =>
      import(
        './components/home/components/home-clients/home-clients.module'
      ).then((m) => m.HomeClientsModule),
  },
  {
    path: 'error_404',
    loadChildren: () =>
      import('./components/page404/page404.module').then(
        (m) => m.Page404Module
      ),
  },
  {
    path: '**',
    redirectTo: '/error_404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
