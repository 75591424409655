import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { servicesUrl } from './servicesUrl';
import { Observable } from 'rxjs';
import { Ilang } from './model';


@Injectable({
  providedIn: 'root'
})
export class HomeService {
  constructor(private http: HttpClient, private urlApi: servicesUrl) {}

  public getLangJson(lang: string): Observable<any> {
    return this.http.get('../../assets/i18n/' + lang + '.json')
  }

  public getLangIsMain(): Observable<Ilang[]> {
    return this.http.get<Ilang[]>(this.urlApi.url_ + '/languages/getLangIsMain');
  }

  public getLangWhereIdLang(id: string): Observable<Ilang[]> {
    return this.http.get<Ilang[]>(this.urlApi.url_+ '/languages/' + id);
  }

  public getData(check: string,  lang: string, FbrnId: string, Type:string,ParentId:string,PostName:string,Pno:string,PostId:string) {
    var bodyFrmData = new FormData();
    bodyFrmData.append("check",check );//"getPost"
    bodyFrmData.append("Lang", lang);// "ar"
    bodyFrmData.append("FbrnId",FbrnId);//""
    bodyFrmData.append("Type", Type);//"Home.MainSlider"
    bodyFrmData.append("ParentId",ParentId);// "NTROOT0"
    bodyFrmData.append("PostName", PostName);//""
    bodyFrmData.append("Pno", Pno);//"5"
    bodyFrmData.append("PostId", PostId);//"5"
    return this.http.post(this.urlApi.url, bodyFrmData);
  }


  public sendMessage(check: string, LangId: string, Name: string, Email: string, Phone: string, MessageTitle: string, Message: string, FbrnId: string, FusrId: string, Fstate: string) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('LangId', LangId);
    formData.append('Name', Name);
    formData.append('Email', Email);
    formData.append('Phone', Phone);
    formData.append('MessageTitle', MessageTitle);
    formData.append('Message', Message);
    formData.append('FbrnId', FbrnId);
    formData.append('FusrId', FusrId);
    formData.append('Fstate', Fstate);
    return this.http.post(this.urlApi.url + '/contact_us/contactUsPost', formData);
  }

}



