import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject } from '@angular/core';
import { HomeService } from './services/home.service';
import { servicesUrl } from 'src/app/services/servicesUrl';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    {
      provide: BsDropdownConfig,
      useValue: { isAnimated: true, autoClose: true },
    },
  ],
})
export class AppComponent {
  year=new Date().getFullYear();
  load: boolean = false;
  id: any;
  isCollapsed = true;
  isCollapsed2 = true;
  isCollapsed3 = true;

  OurWorks: any;
  home: string = '';
  aboutUs: string = '';
  ourWorks: string = '';
  aboutCampay: string = '';
  products: string = '';
  advertisement: string = '';
  News: string = '';
  contactUs: string = '';
  location: string = '';
  openHour_: string = '';
  Phone: string = '';
  Mobile: string = '';
  Email: string = '';
  SiteMap: string = '';
  SocialMedia: string = '';
  Languages: string = '';
  services: string = '';
  statistics: string = '';
  PhotoBrowser: string = '';
  LogoHeader1: string = '';
  LogoFooter: string = '';
  address_: any;
  ph1: string = '';
  ph2: string = '';
  email__: string = '';
  brnAbout: string = '';
  facebook: string = '';
  LnkdIn: string = '';
  pinterest: string = '';
  telegram: string = '';
  tweeter: string = '';
  instagram: string = '';
  youtube: string = '';
  map: string = '';
  openHour: string = '';
  imageUrl: string = '';
  langIsMain: string = '';
  langDir: string = '';
  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private homeService_: HomeService,
    private ServiceUrl_: servicesUrl
  ) {}

  ngOnInit(): void {
    this.load = false;
    this.imageUrl = this.ServiceUrl_.ImgUrl;

    if (localStorage.getItem('lang') == null) {
      this.homeService_.getLangIsMain().subscribe((data) => {
        this.langIsMain = data[0]['LangId'];
        this.langDir = data[0]['LangDir'];

        localStorage.setItem('lang', this.langIsMain);
        this.homeService_.getLangJson(this.langIsMain).subscribe((data) => {
          this.home = data[0]['HeaderAndFooter']['Home'];
          this.aboutUs = data[0]['HeaderAndFooter']['aboutUs'];
          this.ourWorks = data[0]['HeaderAndFooter']['ourWorks'];
          this.aboutCampay = data[0]['HeaderAndFooter']['aboutCampay'];
          this.products = data[0]['HeaderAndFooter']['products'];
          this.advertisement = data[0]['HeaderAndFooter']['advertisement'];
          this.News = data[0]['HeaderAndFooter']['News'];
          this.contactUs = data[0]['HeaderAndFooter']['contactUs'];
          this.location = data[0]['HeaderAndFooter']['location'];
          this.openHour_ = data[0]['HeaderAndFooter']['openHour'];
          this.Phone = data[0]['HeaderAndFooter']['Phone'];
          this.Mobile = data[0]['HeaderAndFooter']['Mobile'];
          this.Email = data[0]['HeaderAndFooter']['Email'];
          this.SiteMap = data[0]['HeaderAndFooter']['SiteMap'];
          this.SocialMedia = data[0]['HeaderAndFooter']['SocialMedia'];
          this.Languages = data[0]['HeaderAndFooter']['Languages'];
          this.services = data[0]['HeaderAndFooter']['services'];
          this.statistics = data[0]['HeaderAndFooter']['statistics'];
          this.PhotoBrowser = data[0]['HeaderAndFooter']['PhotoBrowser'];

          this.homeService_
            .getData(
              'getPost',
              this.langIsMain,
              '',
              'Footer.Data',
              'NTROOT0',
              '',
              '1',
              ''
            )
            .subscribe((data) => {
              //          this.homeService_.getData('getPost', data[0]['LangId'], '', "Footer.Data", "NTROOT0", "", "1", "").subscribe(data => {

              let arr = data as any[];

              this.brnAbout = arr[0]['postSubTitle'];
              this.LogoFooter = this.imageUrl + arr[0]['postImage'];

              this.homeService_
                .getData(
                  'getPost',
                  this.langIsMain,
                  '',
                  'Header.Data',
                  'NTROOT0',
                  '',
                  '1',
                  ''
                )
                .subscribe((data) => {
                  let arr = data as any[];
                  this.LogoHeader1 = arr[0]['postImage'];

                  this.homeService_
                    .getData(
                      'getPost',
                      this.langIsMain,
                      '',
                      'ContactData',
                      'NTROOT0',
                      '',
                      '1',
                      ''
                    )
                    .subscribe((data) => {
                      //          this.homeService_.getData('getPost', data[0]['LangId'], '', "Footer.Data", "NTROOT0", "", "1", "").subscribe(data => {

                      let arr = data as any[];

                      this.address_ = arr[0]['adress1'];
                      this.email__ = arr[0]['email1'];
                      this.ph1 = arr[0]['phone1'];
                      this.ph2 = arr[0]['phone2'];
                      this.facebook = arr[0]['facebook'];
                      this.tweeter = arr[0]['tweeter'];
                      this.instagram = arr[0]['instgram'];
                      this.pinterest = arr[0]['pinterest'];
                      this.telegram = arr[0]['telegram'];
                      this.LnkdIn = arr[0]['linkedin'];
                      this.youtube = arr[0]['youtube'];
                      this.map = arr[0]['map'];
                      this.openHour = arr[0]['openHour'];

                      this.homeService_
                        .getData(
                          'getPost',
                          this.langIsMain,
                          '',
                          'OurWorks',
                          'NTROOT0',
                          '',
                          '10',
                          ''
                        )
                        .subscribe((data) => {
                          this.OurWorks = data;

                          if (this.langDir == 'RTL') {
                            let link1: HTMLLinkElement =
                              this.doc.createElement('link');
                            link1.setAttribute('rel', 'stylesheet');
                            link1.id = 'langfilewebsites';
                            link1.setAttribute(
                              'href',
                              '../assets/css/lang.css'
                            );
                            this.doc.head.appendChild(link1);
                          } else {
                            this.doc
                              .getElementById('langfilewebsites')
                              ?.remove();
                          }
                          this.load = true;
                        });
                    });
                });
            });
        });
      });
    } else {
      this.homeService_
        .getLangWhereIdLang(String(localStorage.getItem('lang')))
        .subscribe((data) => {
          if (data[0]['LangDir'] == 'RTL') {
            let link1: HTMLLinkElement = this.doc.createElement('link');
            link1.setAttribute('rel', 'stylesheet');
            link1.id = 'langfilewebsites';
            link1.setAttribute('href', '../assets/css/lang.css');
            this.doc.head.appendChild(link1);
          } else {
            this.doc.getElementById('langfilewebsites')?.remove();
          }

          this.homeService_
            .getLangJson(String(localStorage.getItem('lang')))
            .subscribe((data) => {
              this.home = data[0]['HeaderAndFooter']['Home'];
              this.aboutUs = data[0]['HeaderAndFooter']['aboutUs'];
              this.ourWorks = data[0]['HeaderAndFooter']['ourWorks'];
              this.aboutCampay = data[0]['HeaderAndFooter']['aboutCampay'];
              this.products = data[0]['HeaderAndFooter']['products'];
              this.advertisement = data[0]['HeaderAndFooter']['advertisement'];
              this.News = data[0]['HeaderAndFooter']['News'];
              this.contactUs = data[0]['HeaderAndFooter']['contactUs'];
              this.location = data[0]['HeaderAndFooter']['location'];
              this.openHour_ = data[0]['HeaderAndFooter']['openHour'];
              this.Phone = data[0]['HeaderAndFooter']['Phone'];
              this.Mobile = data[0]['HeaderAndFooter']['Mobile'];
              this.Email = data[0]['HeaderAndFooter']['Email'];
              this.SiteMap = data[0]['HeaderAndFooter']['SiteMap'];
              this.SocialMedia = data[0]['HeaderAndFooter']['SocialMedia'];
              this.Languages = data[0]['HeaderAndFooter']['Languages'];
              this.services = data[0]['HeaderAndFooter']['services'];
              this.statistics = data[0]['HeaderAndFooter']['statistics'];
              this.PhotoBrowser = data[0]['HeaderAndFooter']['PhotoBrowser'];

              this.homeService_
                .getData(
                  'getPost',
                  String(localStorage.getItem('lang')),
                  '',
                  'OurWorks',
                  'NTROOT0',
                  '',
                  '-1',
                  ''
                )
                .subscribe((data) => {
                  this.OurWorks = data;

                  this.homeService_
                    .getData(
                      'getPost',
                      String(localStorage.getItem('lang')),
                      '',
                      'Header.Data',
                      'NTROOT0',
                      '',
                      '1',
                      ''
                    )
                    .subscribe((data) => {
                      let arr = data as any[];
                      this.LogoHeader1 = arr[0]['postImage'];

                      this.homeService_
                        .getData(
                          'getPost',
                          String(localStorage.getItem('lang')),
                          '',
                          'ContactData',
                          'NTROOT0',
                          '',
                          '1',
                          ''
                        )
                        .subscribe((data) => {
                          //          this.homeService_.getData('getPost', data[0]['LangId'], '', "Footer.Data", "NTROOT0", "", "1", "").subscribe(data => {

                          let arr = data as any[];

                          this.address_ = arr[0]['adress1'];
                          this.email__ = arr[0]['email1'];
                          this.ph1 = arr[0]['phone1'];
                          this.ph2 = arr[0]['phone2'];
                          this.facebook = arr[0]['facebook'];
                          this.tweeter = arr[0]['tweeter'];
                          this.instagram = arr[0]['instgram'];
                          this.pinterest = arr[0]['pinterest'];
                          this.telegram = arr[0]['telegram'];
                          this.LnkdIn = arr[0]['linkedin'];
                          this.youtube = arr[0]['youtube'];
                          this.map = arr[0]['map'];
                          this.openHour = arr[0]['openHour'];

                          this.homeService_
                            .getData(
                              'getPost',
                              String(localStorage.getItem('lang')),
                              '',
                              'Footer.Data',
                              'NTROOT0',
                              '',
                              '1',
                              ''
                            )
                            .subscribe((data) => {
                              let arr = data as any[];
                              this.brnAbout = arr[0]['postSubTitle'];
                              this.LogoFooter =
                                this.imageUrl + arr[0]['postImage'];

                              this.load = true;
                            });
                        });
                    });
                });
            });
        });
    }

  }

  lang(lang: string): void {
    localStorage.setItem('lang', lang);
    this.homeService_.getLangWhereIdLang(lang).subscribe((data) => {
      if (data[0]['LangDir'] == 'RTL') {
        let link1: HTMLLinkElement = this.doc.createElement('link');
        link1.setAttribute('rel', 'stylesheet');
        link1.id = 'langfilewebsites';
        link1.setAttribute('href', '../assets/css/lang.css');
        this.doc.head.appendChild(link1);
      } else {
        this.doc.getElementById('langfilewebsites')?.remove();
      }
    });
    this.homeService_
      .getData(
        'getPost',
        String(localStorage.getItem('lang')),
        '',
        'OurWorks',
        'NTROOT0',
        '',
        '-1',
        ''
      )
      .subscribe((data) => {
        this.OurWorks = data;
      });
    location.replace('');
  }
  onActivate(event: any) {
    window.scroll(0, 0);
  }
}
